<template>
  <div v-if='dialogVisible'>
    <el-dialog title="考点组卷-指定学生"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="960px"
               :before-close="handleClose">
      <div class="head">
        选择:(请先选择试卷地区和科目)
      </div>
      <div style="padding-top:10px">
        <el-form :model="form"
                 ref="formRef"
                 :rules="rules"
                 label-width="90px">
          <el-row :gutter="10">
            <span class="title">试卷设置：</span>
          </el-row>
          <el-row :gutter="10"
                  style="border-bottom:1px solid #eee">
            <el-col :span="8">
              <el-form-item label="试卷名称">
                <el-input clearable
                          placeholder="请输入"
                          style="width:100%"
                          v-model="form.paper_name" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="指定年级"
                            prop="grade_id">
                <el-select v-model="form.grade_id"
                           style="width:100%"
                           disabled
                           placeholder="选择">
                  <el-option v-for="item in gradeList"
                             :key="item.grade_id"
                             :label="item.grade_name"
                             :value="item.grade_id">
                  </el-option>
                </el-select>
                <!-- <el-radio-group v-model="form.grade_id"
                                style="width:100%"
                                disabled>
                  <el-radio v-for="item in gradeList"
                            :key="item.grade_id"
                            :label="item.grade_id">
                    {{item.grade_name}}
                  </el-radio>
                </el-radio-group> -->
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="试卷科目"
                            prop="subject_id">
                <el-select v-model="form.subject_id"
                           @change="getSourceList"
                           disabled
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="item in subjectList"
                             :key="item.subject_id"
                             :label="item.subject_name"
                             :value="item.subject_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="试卷地区"
                            prop="district_id">
                <el-select v-model="form.district_id"
                           style="width:100%"
                           @change="getSourceList('dis')"
                           placeholder="选择">
                  <el-option v-for="item in testingList"
                             :key="item.paper_district_id"
                             :label="item.paper_district_name"
                             :value="item.paper_district_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="年份从"
                            prop="begin_year">
                <el-date-picker v-model="form.begin_year"
                                style="width:100%"
                                :picker-options="pickerOptions1"
                                value-format="yyyy"
                                type="year"
                                placeholder="选择年">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="到"
                            prop="end_year">
                <el-date-picker v-model="form.end_year"
                                style="width:100%"
                                value-format="yyyy"
                                type="year"
                                placeholder="选择年">
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="试卷来源"
                            prop="source_id">
                <el-select v-model="form.source_id"
                           style="width:100%"
                           filterable
                           @change="getSourceList('题目来源')"
                           placeholder="选择">
                  <el-option v-for="item in sourceList"
                             :key="item.source_id"
                             :label="item.source_name"
                             :value="item.source_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <span class="title">题目设置：</span>
          </el-row>
          <el-row :gutter="10"
                  style="border-bottom:1px solid #eee">

            <el-col :span="8">
              <el-form-item label="题目类型">
                <el-select v-model="form.type_id"
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="item in typeList"
                             :key="item.question_type_id"
                             :label="item.question_type_name"
                             :value="item.question_type_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8"
                    v-if="show">
              <!-- 类别只有当科目为语文英语的时候才显示 -->
              <el-form-item label="题目类别">
                <el-select v-model="form.category_id"
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="item in categoriesList"
                             :key="item.question_category_id"
                             :label="item.question_category_name"
                             :value="item.question_category_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="考点">
                <el-cascader v-model="knowledge_ids"
                             ref="cascaderRef"
                             :options="knowledgeList"
                             :props="props"
                             :key='randomId'
                             placeholder="选择"
                             @change="changeKnowledge"
                             collapse-tags
                             filterable></el-cascader>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="8">
              <el-form-item label="二级考点">
                <el-select v-model="form.checkpoints2"
                           multiple
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="(item,index) in options"
                             :key="index"
                             :label="item.text"
                             :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->
            <!-- 
            <el-col :span="8">
              <el-form-item label="试卷学期">
                <el-select v-model="form.semester"
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="item in semesterList"
                             :key="item.value"
                             :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="试卷类型">
                <el-select v-model="form.edu_paper_type"
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="item in paperTypeList"
                             :key="item.paper_type_id"
                             :label="item.paper_type_name"
                             :value="item.paper_type_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <el-col :span="8">
              <el-form-item label="题目数量"
                            prop="questionNumber">
                <el-input clearable
                          v-model="form.questionNumber"
                          style="width:100%"
                          placeholder="请输入" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="题目难度">
                <el-select v-model="form.question_difficulty"
                           style="width:100%"
                           placeholder="选择">
                  <el-option v-for="(item,index) in difficultyList"
                             :key="item.val+''+index"
                             :label="item.text"
                             :value="item.val">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <span class="title">做卷设置：</span>
          </el-row>
          <el-row :gutter="10">
            <!-- <el-col :span="8">
              <el-form-item label="使用类型"
                            prop="is_trial">
                <el-radio-group v-model="form.is_trial">
                  <el-radio :label="0">普通试卷</el-radio>
                  <el-radio :label="1">试用试卷</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="试卷时长">
                <el-input v-model="form.paper_duration"
                          maxlength="4"
                          placeholder="试卷时长(分钟)"
                          style="width:100%" />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item label="开始时间">
                <el-date-picker v-model="form.paper_time"
                                type="datetime"
                                style="width:100%"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                placeholder="选择开始时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="截止时间">
                <el-date-picker v-model="form.end_time"
                                style="width:100%"
                                type="datetime"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                default-time="23:59:59"
                                placeholder="试卷截止时间" />
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="10"
                  style="text-align:center">
            <el-col :span="24">
              <el-button type="primary"
                         @click="toDetails">提交</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getByRadeClassSubject } from '@/api/getSubjectList.js'
import { getFlatlist, getParentIdList, getParentIds } from '@/utils/common.js'
export default {
  data () {
    return {
      pickerOptions1: {
        disabledDate (time) {
          if (time.getFullYear() < 2008 || time.getFullYear() > 2030) {
            return true
          }
          return false
        },
      },
      checkpointsDisable: true, //考点是否禁止点击
      // 仅当科目为语文或者英语时显示
      show: 'true',
      dialogVisible: false,
      form: { select_which: 1 },
      options: [],
      showList: [],
      testingList: [],
      subjectList: [],
      knowledge_ids: [],//直接用form.knowledge_id会出现有时选了没显示的bug，另建一个变量解决
      sourceList: [],
      resultCheckpointsList: [],
      knowledgeList: [],
      flatKnowledgeList: [],
      difficultyList: [],
      props: {
        multiple: true,
        children: 'child',
        value: 'knowledge_id',
        label: 'knowledge_desc',
        checkStrictly: true,
        emitPath: false,// 只返回该节点的值

      },
      typeList: [],
      categoriesList: [],
      randomId: Math.random(),
      semesterList: [
        { label: '上学期', value: 0 },
        { label: '下学期', value: 1 },
        { label: '未指定', value: 2 },
      ],
      gradeList: [],
      paperTypeList: [],
      rules: {
        select_which: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        paper_name: [
          { required: 'true', message: '请填写试卷名称', trigger: 'change' }
        ],
        district_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        type_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        subject_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        source_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        begin_year: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        end_year: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        category_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        questionNumber: [
          { required: 'true', message: '请输入', trigger: 'change' }
        ],
        select_all: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        show_to: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        grade_id: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        semester: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        is_trial: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        edu_paper_type: [
          { required: 'true', message: '请选择', trigger: 'change' }
        ],
        paper_duration: [
          { required: 'true', message: '请输入', trigger: 'change' }
        ],
        grade_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],

      },

    }
  },

  watch: {
    'form.paper_duration': {
      handler (newVal, oldVal) {
        var numberarr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0]
        if (newVal != '' && numberarr.findIndex(item => item == newVal[newVal.length - 1]) == -1) {
          this.form.paper_duration = oldVal
        }
      },
      deep: true
    },
    // 'form.subject_id': {
    //   handler () {
    //     console.log(789789)
    //     this.getSourceList()
    //   },
    //   deep: true
    // }
  },
  methods: {
    gradeChange () {
      this.form.subject_id = ''
      if (this.form.grade_id == '') return
      this.getSubjectList()
    },
    getKnowledgeList () {
      this.knowledge_ids = []
      this.tipList = []
      this.$http({
        url: '/api/v1/question/knowledge',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.knowledgeList = res.data.list.filter(item => {
          return item.grade_id.split(',').includes(this.form.grade_id.toString());
        });

        this.flatKnowledgeList = getFlatlist(this.knowledgeList, 'child')
        if (JSON.parse(this.$route.query.form).knowledge_id) {
          this.knowledge_ids.push(JSON.parse(this.$route.query.form).knowledge_id)
        }

      })
    },
    // 考察范围点击时
    handleRange (e) {

      if (this.form.select_which == e) {
        this.form.select_which = 0
      } else {

        this.$set(this.form, 'select_which', e)
      }
    },
    changeKnowledge (value) {


      let arrTemp = getParentIdList(value, this.flatKnowledgeList, 'knowledge_parent_id', 'knowledge_id', 'knowledge_desc')
      for (let i = 0, len = arrTemp.length; i < len; i++) {
        arrTemp[i] = arrTemp[i].join(' / ')
      }
      console.log(arrTemp, 'aar')
      this.tipList = arrTemp
      this.tipContent = '考点'
    },

    handleClose () {
      this.dialogVisible = false
      this.checkpointsDisable = true
    },
    toDetails () {
      let form = JSON.parse(JSON.stringify(this.form))
      this.$refs.formRef.validate(val => {
        if (!val) {
          return
        }
     

        let submitForm = {
          paper_name: form.paper_name,
          district_id: form.district_id,
          subject_id: form.subject_id,
          source_id: form.source_id,
          question_year_begin: form.begin_year,
          question_year_end: form.end_year,
          questionType: form.type_id ? [form.type_id] : '',
          questionCategory: form.category_id,
          knowledge_id: this.knowledge_ids&&this.knowledge_ids.length>0?this.knowledge_ids:[this.knowledge_ids],
          // checkpoints2: form.checkpoints2 || [],
          question_num: form.questionNumber,
          select_all: form.select_all,
          show_to: form.show_to,
          paper_time: form.paper_time,
          question_difficulty: form.question_difficulty ? form.question_difficulty.split(',') : '',
          semester: form.semester,
          is_trial: form.is_trial,
          edu_paper_type: form.edu_paper_type,
          given_garde: form.grade_id,
          paper_duration: form.paper_duration,
          end_time: form.end_time,
          user_ids: form.user_ids,
          isBrackets: form.isBrackets,
          select_which: 1,
        }


        this.$http({
          url: '/api/v1/combination/smart_create',
          method: 'post',
          data: submitForm
        }).then(res => {
          if (res.status == 400) {
            this.$notify({
              title: '提示',
              message: res.msg,
              type: 'warning'
            });
          } else {
            this.$notify({
              title: '提示',
              message: '成功!',
              type: 'success'
            });
            this.handleClose()
            this.$router.push(`/testPapercomposition/autonomously/addDetails?type=testting&edu_paper_id=${res.data.edu_paper_id}`)
          }
        })

      })
    },
    // 
    async getDistrictList () {
      await this.$http({
        url: '/api/v1/combination/region',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          grade_id: this.form.grade_id,
        }

      }).then(res => {
        this.showList = res.data.showToList
        this.testingList = res.data.districtList
        this.form.district_id = this.testingList[0].paper_district_id
        this.getDiffcultyList()
        this.getGradeList()
        this.getPaperTypeList()

      })
    },

    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    getPaperTypeList () {
      this.$http({
        url: '/api/v1/public/paper_type',
        method: 'GET'
      }).then(res => {
        this.paperTypeList = Object.values(res.data)
      })
    },
    getDiffcultyList () {
      this.$http({
        url: '/api/v1/combination/question_difficulty',
        method: 'get',
      }).then(res => {
        this.difficultyList = res.data
      })
    },
    async getSubjectList () {
      let form = {
        grade_id: this.form.grade_id
      }
      // this.form.subject_id = ''
      const { data } = await getByRadeClassSubject(form)
      this.subjectList = Object.values(data)
    },
    // async getSourceList (str) {
    //   if (str == 'dis' && this.form.subject_id == '') return
    //   this.subjectList.forEach(item => {
    //     if (item.subject_id == this.form.subject_id) {
    //       this.show = item.subject_name == '语文' || item.subject_name == '英语' ? true : false
    //       if (this.show) {
    //         this.form.category_id = ''
    //       }
    //     }
    //   });
    //   this.getKnowledgeList()

    //   if (str != '题目来源') {
    //     this.form.source_id = ''
    //   }

    //   await this.$http({
    //     url: '/api/v1/combination/get_select',
    //     method: 'post',
    //     data: {
    //       district_id: this.form.district_id,
    //       subject_id: this.form.subject_id,
    //       source_id: this.form.source_id,
    //       begin_year: this.form.begin_year,
    //       end_year: this.form.end_year,
    //       grade_id: this.form.grade_id
    //     }
    //   }).then(res => {

    //     if (str !== '题目来源') {
    //       this.sourceList = res.data.sourceList
    //       this.form.source_id = this.sourceList[0].template_id
    //     }
    //     this.typeList = res.data.pointLIst.types
    //     // this.form.type_id = this.typeList[0].question_type_id
    //     this.categoriesList = res.data.pointLIst.categories
    //     // this.form.category_id = this.categoriesList[0].question_category_id
    //     this.resultCheckpointsList = res.data.pointLIst.resultCheckpoints
    //     // this.form.knowledge_id = []
    //     let checkponits = []
    //     for (let i of this.resultCheckpointsList) {
    //       checkponits.push(i.id)
    //     }
    //     this.form.knowledge_id = this.form.knowledge_id.filter(item => {
    //       return checkponits.includes(item)
    //     })
    //     if (this.form.knowledge_id && this.form.knowledge_id.length > 0) {
    //       this.checkChange()
    //       this.checkpointsDisable = true
    //     } else {
    //       this.checkpointsDisable = false
    //       this.options = []
    //       this.form.checkpoints2 = []
    //     }

    //   })
    // },
    async getSourceList (str) {

      this.$http({
        url: '/api/v1/public/sum_up_source',
        method: 'get',
      }).then(res => {
        this.sourceList = res.data
      })


      if (this.form.questionSubject == '') return


      this.subjectList.forEach(item => {
        if (item.subject_id == this.form.questionSubject) {
          this.show = item.subject_name == '英语' ? true : false
          if (this.show) {
            this.form.questionCategory = ''
          } else {
            this.form.questionType = ''
          }
        }
      });

      // if (str != '题目来源' && str != 'year') {
      //   this.form.source_id = []
      // }
      // let source_id = JSON.parse(JSON.stringify(this.form.source_id))
      // if (source_id.indexOf(0) != -1) {
      //   source_id.splice(this.form.source_id.indexOf(0), 1)
      // }

      await this.$http({
        url: '/api/v1/public/paper_type_category',
        method: 'get',
        params: {
          // district_id: this.form.district_id.join(','),
          // district_id: this.form.district_id,
          subject_id: this.form.questionSubject,
          // source_id: source_id,
          // question_year_begin: this.form.question_year_begin,
          // question_year_end: this.form.question_year_end,
          // grade_id: this.form.grade_id || this.grade_id,
          // class_id: this.form.class_id
        }
      }).then(res => {
        console.log('res', res);

        this.typeList = []
        res.data.question_type_list.forEach(item => {
          if (item.question_type_id != 0) {
            this.typeList.push(item)
          }
        })
        // this.form.questionType = this.typeList[0].question_type_id
        this.categoriesList = res.data.question_category_list
        // if (this.categoriesList && this.categoriesList.length > 0) {
        //   this.form.questionCategory = this.categoriesList[0].question_category_id
        // }
        // this.resultCheckpointsList = res.data.pointLIst.resultCheckpoints
        // this.checkPoint2Options = []
        // this.form.check_point_id = []
        // this.form.checkpoints2 = []
        this.form.questionType = []
        this.form.questionCategory = []
        this.questionNumberDetail = {}
        this.getKnowledgeList()
        // this.getsectionList()   //章节
      })
    },
    // 复制给外部调用  不清空考点输入框
    async getSourceList1 (str) {

      if (str == 'dis' && this.form.subject_id == '') return
      this.subjectList.forEach(item => {
        if (item.subject_id == this.form.subject_id) {
          this.show = item.subject_name == '语文' || item.subject_name == '英语' ? true : false
          if (this.show) {
            this.form.category_id = ''
          }
        }
      });
      this.getKnowledgeList()
      if (str != '题目来源') {
        this.form.source_id = ''
      }
      this.form.source_id = ''
      await this.$http({
        url: '/api/v1/combination/get_select',
        method: 'post',
        data: {
          district_id: this.form.district_id,
          subject_id: this.form.subject_id,
          source_id: this.form.source_id,
          begin_year: this.form.begin_year,
          end_year: this.form.end_year,
          grade_id: this.form.grade_id
        }
      }).then(res => {
        if (str !== '题目来源') {
          this.sourceList = res.data.sourceList
          this.form.source_id = this.sourceList[0].template_id
        }
        this.typeList = res.data.pointLIst.types
        // this.form.type_id = this.typeList[0].question_type_id
        this.categoriesList = res.data.pointLIst.categories
        // this.form.category_id = this.categoriesList[0].question_category_id
        this.resultCheckpointsList = res.data.pointLIst.resultCheckpoints
      })
    },
    async checkChange () {
      if (!this.form.knowledge_id) {
        return
      }
      let knowledge_id = this.form.knowledge_id.join(',')
      await this.$http({
        url: '/api/v1/combination/second_point',
        method: 'post',
        data: {
          checkpoints: knowledge_id
        }
      }).then(res => {
        this.options = res.data.list
      })
    },
    clickRadio () {
      console.log('form.select_all', this.form.select_all);
      if (this.form.select_all == '') {
        this.form.select_all = '1'
      } else {
        this.form.select_all = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hide_label {
  ::v-deep .el-radio__label {
    display: none;
  }
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.title {
  font-size: 15px;
  font-family: PingFang SC-Regular, PingFang SC;
  margin-left: 24px;
  font-weight: 700;
  line-height: 36px;
  color: #333333;
}
::v-deep .el-radio {
  margin-right: 12px;
}
</style>